import React from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import moment from 'moment';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { saveToLocalStorage } from '../../../Helper/helper';

const Item = styled(Card)(({ theme }) => ({
    backgroundColor: "#fff",
    boxShadow: 'none',
    marginBottom: 20,
}));

const FeatureButton = {
    fontFamily: 'Noto Sans Malayalam, sans-serif',
    background: '#176B87',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '1.5em',
    margin: '20px',

};

const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: "10px",
    position: 'relative',
};

const boxStyle2 = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingRight: "10px",
    paddingLeft: "10px",
    borderBottom: '1px solid #e5e5e5',
    marginTop: '15px',
    marginBottom: '15px',
};

const boxStyleSecondFlex = {
    display: 'flex',
    flexDirection: 'row',
    gap: "10px",
    flexWrap: 'nowrap',
    alignItem: 'flex-start',
    marginBottom: '10px'
};

function Features({ groupedCategory }) {
    const navigate = useNavigate();

    /**
     * Invokes when a news click happens
     * @param {string} id the selected news id
     * @param {string} slug the slug of the selected news
     */
    const onClickNews = (id, slug) => {
        saveToLocalStorage(window.location.pathname, window.scrollY);
        navigate(`/user/news/details/${slug}`);
    }

    const moreNewsClick = (category) => {
        saveToLocalStorage(window.location.pathname, window.scrollY);
        let type = groupedCategory[category];
        navigate(`/user/news/category/list`, {
            state: {
                category: category,
                type: type[0]?.type,
            }
        });
    };

    return (
        <>
            {Object.keys(groupedCategory).map((cat, index) => {
                const renderFilteredContent = (htmlContent) => {
                    let arr = [];
                    parse(htmlContent, {
                        replace: (domNode) => {
                            arr.push(domNode);
                            return null;
                        },
                    });

                    const imageNode = arr.find((node) => node?.name === 'img');
                    const iframeNode = arr.find((node) => node?.name === 'iframe');
                    const descriptionNode = arr.filter((node) => node?.type === "text");

                    // Image rendering
                    const image = imageNode ? (
                        <img
                            alt="News"
                            src={imageNode?.attribs?.src || "/DefaultImages/news_default_img.jpeg"}
                            style={{ width: '100%', height: '400px', borderRadius: '5px' }}
                            onClick={() => onClickNews(groupedCategory[cat][0]._id, groupedCategory[cat][0].slug)}
                        />
                    ) : iframeNode ? (
                        // Iframe rendering
                        <iframe src={iframeNode?.attribs?.src}
                            style={{ width: '100%', height: '400px', borderRadius: '5px' }}
                            title="Video"
                        />
                    ) : (
                        // Default image rendering
                        <img
                            alt="Default"
                            src="/DefaultImages/news_default_img.jpeg"
                            style={{ width: '100%', height: '400px', borderRadius: '5px' }}
                            onClick={() => onClickNews(groupedCategory[cat][0]._id, groupedCategory[cat][0].slug)}
                        />
                    )

                    const description = descriptionNode.map((node) => node?.data).join(" ").trim();

                    return { image, description };
                };

                const { image, description } = renderFilteredContent(groupedCategory[cat][0]?.content);
                return (
                    <Item key={index}>
                        <Box>
                            <Button variant="contained" style={FeatureButton} onClick={() => moreNewsClick(cat)}>{cat}</Button>
                        </Box>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                                <Box sx={boxStyle}>
                                    <div>
                                        {image}
                                    </div>
                                    {/* <Card style={{ boxShadow: 'none', backgroundColor: '#F1F1F1', padding: "10px", margin: '20px' }}> */}
                                    <span className='worldFlex'>
                                        <span className='worldChildFlex'>
                                            {/* <CalendarTodayOutlinedIcon /> */}
                                            <p className='date-time-text-style'>{moment(groupedCategory[cat][0]?.date).format('DD-MM-YYYY')}</p>
                                        </span>
                                        <span className='worldChildFlex'>
                                            <VisibilityOutlinedIcon sx={{ height: '20px', width: '20px', color: '#9DC284' }} />
                                            <p className='date-time-text-style'>{groupedCategory[cat][0]?.viewsCount}</p>
                                        </span>
                                    </span>
                                    {/* </Card> */}
                                    <Box id={groupedCategory[cat][0]._id} style={{ flex: 1 }}>
                                        <div className='card-content-for-world-and-news-session'>
                                            <p className="hover-blue-title-text-header" dangerouslySetInnerHTML={{ __html: groupedCategory[cat][0]?.title }} onClick={() => onClickNews(groupedCategory[cat][0]._id, groupedCategory[cat][0].slug)}></p>
                                            <p className='description-text-style'>{description}</p>
                                        </div>
                                    </Box>
                                    <span className="category-card-black">{groupedCategory[cat][0]?.categoryName}</span>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <Box>
                                        {groupedCategory[cat].slice(1, 5).map((f, j) => {
                                            const renderFilteredContent = (htmlContent) => {
                                                let arr = [];
                                                parse(htmlContent, {
                                                    replace: (domNode) => {
                                                        arr.push(domNode);
                                                        return null;
                                                    },
                                                });

                                                const imageNode = arr.find((node) => node?.name === 'img');
                                                const iframeNode = arr.find((node) => node?.name === 'iframe');
                                                const descriptionNode = arr.filter((node) => node?.type === "text");

                                                // Image rendering
                                                const image = imageNode ? (
                                                    <img
                                                        alt="News"
                                                        src={imageNode?.attribs?.src || "/DefaultImages/news_default_img.jpeg"}
                                                        style={{ width: '85px', height: '85px', objectFit: 'cover', borderRadius: '5px' }}
                                                        onClick={() => onClickNews(f._id, f.slug)}
                                                    />
                                                ) : iframeNode ? (
                                                    // Iframe rendering
                                                    <iframe src={iframeNode?.attribs?.src}
                                                        style={{ width: '85px', height: '85px', borderRadius: '5px' }}
                                                        title="Video"
                                                    />
                                                ) : (
                                                    // Default image rendering
                                                    <img
                                                        alt="Default"
                                                        src="/DefaultImages/news_default_img.jpeg"
                                                        style={{ width: '85px', height: '85px', objectFit: 'cover', borderRadius: '5px' }}
                                                        onClick={() => onClickNews(f._id, f.slug)}
                                                    />
                                                )

                                                const description = descriptionNode.map((node) => node?.data).join(" ").trim();

                                                return { image, description };
                                            };

                                            const { image, description } = renderFilteredContent(f?.content);
                                            return (
                                                <Box sx={boxStyle2} id={f._id} key={j}>
                                                    <Box style={boxStyleSecondFlex}>
                                                        <div>
                                                            {image}
                                                        </div>
                                                        <Box>
                                                            <p className="hover-blue-title-text-header" style={{ marginTop: '0px' }} dangerouslySetInnerHTML={{ __html: f?.title }} onClick={() => onClickNews(f._id, f.slug)}></p>
                                                            {/* <p className='description-text-style'>{description}</p> */}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <div className='more-news-container-style'>
                                        <span className='more-from-latest-news-style' onClick={() => moreNewsClick(cat)}>
                                            <span>READ MORE</span>
                                            <DoubleArrowIcon color='error' sx={{ cursor: 'pointer' }} />
                                        </span>
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Item>
                )
            })}
        </>
    );
}

export default Features;
