import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { FacebookIcon, WhatsappIcon } from 'react-share';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { saveToLocalStorage } from '../../../Helper/helper';

const footer = {
    backgroundColor: 'black',
    color: 'white',
}

const Footer = () => {

    return (
        <Box style={footer}>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={12}>
                        <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', gap: 20, paddingBottom: 50, alignItem: 'flex-start', width: '80%' }}>
                            <img alt="complex" src={"/UserSideImages/logo.png"} style={{ width: '50%', height: '40%' }} />
                            <Typography variant="body2">At OnlineGoodNews.com, our mission is to serve the global Christian community by delivering timely and trustworthy news, stories, and insights about churches, missions, and ministries from around the world. We aim to unite believers, highlight impactful ministries, and celebrate the work of God through His people.As a reliable, unbiased source of information, we help believers stay informed and connected to the larger body of Christ. Our content is designed to encourage, uplift, and empower the community for the Glory of God.</Typography>
                            <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 20, padding: '30px 0px 0px 0px' }}>
                                <WhatsappIcon size={32} style={{ cursor: 'pointer' }} onClick={() => { window.open('https://www.whatsapp.com', '_blank') }} />
                                <FacebookIcon size={32} style={{ cursor: 'pointer' }} onClick={() => { window.open('https://www.facebook.com', '_blank') }} />
                                <YouTubeIcon style={{ background: '#E44B07', height: 32, width: 32, cursor: 'pointer' }} onClick={() => { window.open('https://www.youtube.com', '_blank') }} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12}>
                        <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', gap: 20, paddingBottom: 50, justifyContent: 'center', width: '90%' }}>
                            <Typography variant="body1"><b>Contact Us</b></Typography>
                            <Typography variant="body2">We’re here to support and connect with you! Whether you have feedback, news tips, or inquiries, please don’t hesitate to reach out:</Typography>
                            <Typography variant="body1"><b>Phone Support:</b></Typography>
                            <Typography variant="body2"><b>India:</b> +91 94473 72726 / +91 99462 05422 / +91 94005 20909</Typography>
                            <Typography variant="body2"><b>USA:</b> +1 (469) 573 0264</Typography>
                            <Typography variant="body2"><b>UK:</b> +44 7951 963062</Typography>
                            <Typography variant="body2"><b>Australia:</b> +61 490043301</Typography>
                            <Typography variant="body2"><b>Gulf:</b> +971 50 354 0676</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12}>
                        <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', gap: 20, paddingBottom: 50, width: '90%' }}>
                            <Typography variant="body1"><b>Mailing Address:</b></Typography>
                            <Typography variant="body2">ONLINEGOODNEWS.COM</Typography>
                            <Typography variant="body2">Kottayam 686 004, Kerala, India</Typography>
                            <Typography variant="body1"><b>Office Hours:</b></Typography>
                            <Typography variant="body2">Monday - Friday: 9:00 AM - 6:00 PM (IST)</Typography>
                            <Typography variant="body2">Closed on Saturdays, Sundays, and Public Holidays</Typography>
                            <Typography variant="body1"><b>Email Us:</b></Typography>
                            <Typography variant="body2"><b>General Inquiries:</b> info@onlinegoodnews.com</Typography>
                            <Typography variant="body2"><b>News Tips & Editorial:</b> editor@onlinegoodnews.com</Typography>
                            {/* <Typography variant="body2"><b>Advertising & Partnerships:</b> ads@onlinegoodnews.com</Typography> */}
                        </Box>
                    </Grid>
                </Grid>
                <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', gap: 20, paddingBottom: 50, paddingTop: 30, borderTop: '1px solid #2a2b2d' }}>
                    <Typography variant="body2">
                        © {new Date().getFullYear()} All rights reserved.
                    </Typography>
                    <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 20 }}>
                        <Link className='footer-link-style' to="/" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>Home</Link>
                        <Link className='footer-link-style' to="/about-us" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>About Us</Link>
                        <Link className='footer-link-style' to="" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>News</Link>
                        <Link className='footer-link-style' to="" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>Contact Us</Link>
                        <Link className='footer-link-style' to="/privacy" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>Privacy Policy</Link>
                        <Link className='footer-link-style' to="/terms-and-condition" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>Terms of Service</Link>
                        {/* <Link className='footer-link-style' to="/terms-and-condition" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>Terms & Conditions</Link>
                        <Link className='footer-link-style' to="/support" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>Support</Link>
                        <Link className='footer-link-style' to="/privacy" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>Privacy</Link> */}
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;