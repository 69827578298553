import React, { useEffect, useState } from 'react'
import World from '../../Components/World/World.js'
import Advertise from '../../Components/Advertise/Advertise.js'
import { Link } from 'react-router-dom'
import NewsSection from '../News/NewsSection.js'
import { bannerAdds, getAllBox, getFilteredNewsData, getGroupedCategory, latestHomeThreeNews } from '../../../Services/UserServices.js'
import Loader from '../../../Components/Loader/Loader.js'
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { scrollToLastVisitedItem } from '../../../Helper/helper.js'

function UserDasbboard() {

    const [latestNews, setLaststNews] = useState([]);
    const [bannerAds, setBannerAds] = useState([]);
    const [groupedCategory, setGroupedCategory] = useState([]);
    const [ads, setAds] = useState([]);
    const [latestNewsSession, setLatestNewsSession] = useState([]);

    // Loader
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        window.scrollTo(0, 0);
        setLoading(true);
        try {

            await latestHomeThreeNews().then((res) => {
                let data = res?.data?.news;
                setLaststNews(data);
            })
            await bannerAdds().then((res) => {
                let data = res?.data?.ads;
                setBannerAds(data);
            })
            await getGroupedCategory().then((res) => {
                let data = res?.data?.newsByCategory;
                setGroupedCategory(data);
            })
            await getAllBox().then((res) => {
                let data = res?.data?.ads;
                setAds(data);
            })

            let filter = {
                "categoryName": "",
                "type": "Main",
                "count": 5
            }
            await getFilteredNewsData(filter).then((res) => {
                let data = res?.data?.latestNews;
                setLatestNewsSession(data);
            })
        } catch (err) {
            setLoading(false);
            console.log(err)
        } finally {
            setLoading(false);
            scrollToLastVisitedItem();
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, []);


    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <div style={{ paddingTop: 20 }}>
                <World latestNews={latestNews} />
            </div>
            <div style={{ paddingTop: 20 }}>
                <Advertise bannerAds={bannerAds} />
            </div>
            <div style={{ paddingTop: 20 }}>
                <NewsSection groupedCategory={groupedCategory} ads={ads} filteredNews={latestNewsSession} />
            </div>
            <div>
                <div className="slide-container">
                    {bannerAds.length > 1 ? (
                        <Zoom scale={0.4} defaultIndex={0}>
                            {bannerAds.map((each, index) => (
                                <Link to={each?.link} key={index}>
                                    <img className='image-responsive-slider' src={each?.image?.url} alt={`Ad ${index + 1}`} />
                                </Link>
                            ))}
                        </Zoom>
                    ) : (
                        <Link to={bannerAds[0]?.link}>
                            <img className='image-responsive-slider' src={bannerAds[0]?.image?.url} alt="Ad" />
                        </Link>
                    )}
                </div>
            </div>
            {/* <div style={{ paddingTop: 50 }}>
                <FeaturedArticles />
            </div> */}
            {/* <div style={{ paddingTop: 20 }}>
                <Link to="">
                    <img alt="complex" src={"/UserSideImages/img_ad11.jpg"} style={{ width: '100%', height: '100%' }} />
                </Link>
            </div> */}
        </div>
    )
}

export default UserDasbboard;